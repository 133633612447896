.headingCard {
	display: flex;
	align-items: center;
	height: 7rem;
}
.map {
	margin: 5rem 3rem 0;
}
.iframe {
	height: 15rem;
}
.icons {
	font-size: 1.5rem;
	color: #c97c5d;
}
.col1B {
	padding-left: 1rem;
	display: inline;
}

.textAlign {
	/* text-align: center; */
}

.contact {
	margin: 2rem 3rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 100;
	font-size: .80rem;
}
.link {
	text-decoration: none;
	color: gray;
}
.link:hover {
	color: rgb(83, 80, 80);
}
.bottomSpace {
	margin-bottom: 7rem;
}

@media screen and (max-width: 992px) {
	.iframe {
		padding: 0;
	}
	.map {
		margin: 5rem 0 0;
	}
	.contact {
		margin: 2rem 0;
	}
	.bottomSpace {
		margin-top: 2rem;
	}
}
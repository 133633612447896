.col {
  display: table-cell;
}
.card {
  margin: .5rem;
}
.imageParent {
  height: 300px;
  overflow: hidden;

}
.image {
  width: 100%;
  height: 100%;
  background-image: url("https://images.unsplash.com/photo-1463171515643-952cee54d42a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80");
  background-position: center;
  background-size: cover;
  object-fit: cover;
  transition: all 1.25s;
}
.imageParent:hover .image,
.imageParent:focus .image {
  transform: scale(1.2);
  cursor: pointer;
}
.title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.desc {
  color: #615d6f;
  font-size: .85rem;
  line-height: 1.15rem;
}
.date {
  font-size: .70rem;
  color: #615d6f;
}

.buttons {
  margin: .20rem;
}
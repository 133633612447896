.container {
  margin-top: 5rem;
}
.mainRow {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: cneter;
  margin: 0 3rem 5rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 100;
}
.col1 {
	
}

@media screen and (max-width: 992px) {
	.mainRow {
		margin: 0;
		margin-left: 0;
		margin-right: 0;
	}
}
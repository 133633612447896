.container {
	margin-top: 5rem;
}
.mainRow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ul {
  display: table;
  width: 100%;

  font-family: 'Montserrat', sans-serif;
  font-weight: 100;
}
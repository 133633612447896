.container {
	margin-top: 5rem;
	padding-left: 0;
	padding-right: 0;
}
.mainRow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ul {
  display: table;
  width: 100%;

  font-family: 'Montserrat', sans-serif;
	font-weight: 100;
}
.bottomSpace {
  margin-bottom: 5rem;
}